#left-bar-navigation:hover[data-first=false] .chevron-left {
    display: flex !important;
    color: white !important;
}

#right-bar-navigation:hover[data-last=false] .chevron-right {
    display: flex !important;
    color: white !important;
}

.area-build-house[data-maxCount=true] {
    height: 100%;
}

.area-build-house[data-maxCount=false] {
    height: attr(data-count) "%";
}
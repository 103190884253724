.button-gradient-type-one {
    background: #ebecf1 !important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #ebecf1 , #ffe8f2) !important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #ebecf1 , #ffe8f2) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    backdrop-filter: blur(20px);

    * {
        color: #000000 !important;
    }
}

.button-gradient-type-two {
    background: #007991 !important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom,  #ebecf1, #ffede8) !important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom,  #f1ebed, #ffede8) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    backdrop-filter: blur(20px);

    * {
        color: #000000 !important;
    }
}

.button-gradient-type-three {
    background: #007991 !important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom,  #ebecf1, #e8f2ff) !important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom,  #f1ebed, #e8f2ff) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    backdrop-filter: blur(20px);

    * {
        color: #000000 !important;
    }
}

.button-gradient-type-four {
    background: #007991 !important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom,  #ebecf1, #e8fffa) !important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom,  #f1ebed, #e8fffa) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    backdrop-filter: blur(20px);

    * {
        color: #000000 !important;
    }
}
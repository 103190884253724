/*
    APPLY GLOBAL STYLES TO ANY SCREEN
*/
.general-container {
    width: 50vw;
    height: 100vh;
    padding: 60px;
    background-color: #202020;
    background-image: url('../../../../assets/img/harvey-print-general-background.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.general-box {
    width: 100%;
    min-height: 96px;
    height: auto;
    max-height: 296px;
    background-color: #272727;
    border: 1px solid #3E3E3E;
    border-radius: 16px;
    padding: 60px;

    font-size: 1rem;
    font-weight: 500;
    color: #ABABAB;

    position: relative;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.general-text-bold {
    font-size: 1.3rem;
    font-weight: 600;
    color: white;
}

.general-text-label {
    font-size: 1rem;
    font-weight: 400;
    color: #ABABAB;
}

.line-separator {
    width: 100%;
    height: 1px;
    border-radius: 2px;
    background-color: #343434;
    display: block;
}

/*
APPLY GLOBAL STYLES ONLY TO SCREEN
*/
@media screen {
    #container-printable {
        width: 100%;
        height: 100%;
        background-color: #202020;

        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: start;

        overflow-y: hidden;
        overflow-x: hidden !important;
    }

}

@media screen and (max-width: 800px) {
    .general-container {
        width: 100% !important;
        padding: 10px !important;
        padding-top: 20px !important;
    }
}

/*
    APPLY GLOBAL STYLES ONLY TO PRINT VIEWS
*/
@media print {

    html,
    body,
    #root {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        border: 0;
    }

    #container-printable {
        width: 100%;
        height: 100%;
        background-color: #202020;

        display: block;
    }

    .general-container {
        width: 100%;
        height: 100%;
    }
}
#mcf-ai-aside-container {
    width: 30vw;
    height: 100vh;
    top: 0vh;
    right: 0vw;
}

#mcf-ai-display-list {
    background: #A1FFCE;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #fefff8 , #effff7);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #fefff8 , #effff7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    backdrop-filter: blur(20px);

}

#mcf-ai-display-expand-controller:checked ~ #mcf-ai-aside-container {
    width: calc(100vw - 108px) !important;
}

.option-action-bar:hover .options-action {
    display: flex;
}

@media not all and (min-width: 640px) {
    #mcf-ai-aside-container {
        height: calc(100vh - 70px) !important;
        margin-top: 70px !important;
    }
}
#red-container {
    height: 100%;
    border-top: 1px solid #3E3E3E;
    background-color: #202020;

    display: flex;
    flex-flow: column;
    align-items: start;
    justify-content: start;
    gap: 4rem;

    & .red-titles {
        font-size: 2rem;
    }
}

#red-ul {
    width: 100%;
    height: auto;

    display: flex;
    flex-flow: column;
    gap: 0;
}

#red-footer-title {
    width: 100%;
    height: auto;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: start;
    gap: 2rem;

    & svg {
        margin-top: 5px;
    }
}

#red-footer {
    width: 100%;
    height: auto;

    display: flex;
    flex-flow: column;
    align-items: start;
    justify-content: start;
    gap: 1rem;
}

#red-footer-content {
    width: 100%;
    height: auto;

    display: flex;
    flex-flow: row nowrap;
    align-items: start;
    justify-content: space-between;
    gap: 1rem;

    & .general-box {
        max-height: 350px;
        height: 296px;
        padding: 30px;

        flex-flow: column;
        align-items: start;
        justify-content: start;
        gap: 0.75rem;

        & .general-text-bold {
            font-size: 1.25rem;
        }

        & .general-text-label {
            font-size: 1.2rem;
        }

    }

    & .general-box:nth-child(1) {
        background-color: transparent;
        border: none;
    }

    & .general-box:nth-child(3) {
        & .general-text-bold {
            color: #1CB978;
        }
    }

}

.red-li {
    width: 100%;
    min-height: 50px;
    height: auto;
    padding-left: 20px;
    padding-bottom: 50px;

    position: relative;

    display: flex;
    flex-flow: row nowrap;
    align-items: start;
    justify-content: space-between;

    &::before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #1CB978;

        position: absolute;
        top: 13px;
        left: 0;
    }

    &:has(+ .red-li) {
        &::after {
            content: '';
            width: 2px;
            height: calc(100% - 5px - 14px);
            background-color: #1CB978;

            position: absolute;
            top: calc(5px + 14px);
            left: 4px;
        }
    }

    &~.red-li {
        &::before {
            top: 0px;
        }

        &::after {
            height: 100%;
            top: 0px;
        }
    }
}

.red-li-header-text {
    font-weight: 400;
    font-size: 1.25rem;
    margin-top: 5px;
}

.red-li-footer-text {
    display: flex;
    flex-flow: column;
    gap: 0rem;
}

.li-footer-text-tag {
    font-size: 1.2rem;
    font-weight: 500;
    color: #1CB978;
    align-self: flex-end;
    cursor: default;
    user-select: none;
    pointer-events: none;
}

@media screen {}

@media print {
    #red-container {
        height: 100%;
        gap: 5rem;
    }

    .red-li {
        height: 70px;
        padding-bottom: 70px;
    }

    .red-li-header-text {
        width: 70%;
    }

    #red-footer-content {
        & .general-box {

            padding: 20px;
            align-items: center;
            justify-content: center;

            & .general-text-bold,
            .general-text-label {
                font-size: 12.5pt;
            }
        }

        & .general-box:nth-child(1) {
            align-items: start;
        }

        & .general-box:nth-child(3) {
            & .general-text-bold {
                align-self: start;
            }
        }
    }
}
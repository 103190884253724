/*
    APPLY GLOBAL STYLES TO ANY SCREEN
*/

.amounts-ul {
    width: 100%;
    height: auto;
    padding: 0;

    margin: 0;

    display: flex;
    flex-flow: column;
    gap: 1.25rem;

    & .amounts-ul-li {
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0;

        display: flex;
        flex-flow: row nowrap;
        align-items: start;
        justify-content: start;
        gap: 2.3rem;
    }

    & .amounts-li-icon {
        width: 43px;
        height: 43px;
    }

    & .amounts-li-content {
        flex: 1;

        display: flex;
        flex-flow: column;
        align-items: start;
        justify-content: start;
        gap: 1rem;

        & .general-text-label {
            margin-top: -5px;
        }
    }
}

#amounts-container {
    height: 100%;
    border-top: 1px solid #3E3E3E;
    background-color: #202020;

    position: relative;

    display: flex;
    flex-flow: column;
    align-items: start;
    justify-content: space-between;
    gap: 2rem;
}

#amounts-header {
    width: 100%;
    height: auto;

    display: flex;
    flex-flow: column;
    gap: 0.875rem;

    & .general-text-bold {
        font-size: 2rem;
    }

    & .general-text-label {
        font-size: 1.25rem;
    }
}

#amounts-main-box {
    background-image: url("../../../../../../assets/img/harvey-print-amounts.png");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    flex-flow: row nowrap;
    align-items: start;
    justify-content: space-between;
}

#amounts-main-box-content {
    flex: 1;
    max-width: 70%;
    height: 100%;

    display: flex;
    flex-flow: column;
    gap: 1.4rem;

    & .general-text-label {
        color: white;
    }
}

#amounts-main-box-header {
    width: 100%;
    height: auto;

    & #amounts-tag {
        min-width: 92px;
        width: auto;
        max-width: 50%;
        height: 31px;
        border-radius: 20px;
        background-color: white;

        font-size: 15px;
        font-weight: 400;
        color: #01CC9D;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
    }
}

#amounts-main-box-columns {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: repeat(2, 1fr);

    & .main-box-column {
        display: flex;
        flex-flow: column;
        align-items: start;
        justify-content: space-between;
        gap: 2rem;
    }

    & .main-box-column:last-child {
        align-items: end;
    }

    & .main-box-column {
        & .box-text-group {
            display: flex;
            flex-flow: column;
            align-items: start;
            justify-content: start;
        }
    }
}

#box-column-two {
    & .general-text-bold {
        font-size: 1.3rem;
        font-weight: 700;
        margin-top: -2px;
    }
}

/*
APPLY GLOBAL STYLES ONLY TO SCREEN
*/
@media screen {}

/*
    APPLY GLOBAL STYLES ONLY TO PRINT VIEWS
*/
@media print {

    #amounts-main-box {
        max-height: none;
        height: 260px;

        padding-bottom: 20px;
        padding-top: 20px;
    }
}
/*
    APPLY GLOBAL STYLES TO ANY SCREEN
*/
#home-container {
    background-image: url("../../../../../../assets/img/harvey-print-home.png");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}

#home-header {
    width: 100%;
    height: auto;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: start;
    gap: 1rem;
}

#home-header {
    & span {
        width: 13rem;
        padding: 8px;
        border: 1px solid white;
        border-radius: 25px;

        cursor: default;
        user-select: none;
        pointer-events: none;

        font-size: 1.2rem;
        font-weight: 500;
        color: white;
        text-align: center;
    }

    & span:nth-child(2) {
        width: 6rem;
    }
}

#home-footer {
    width: 100%;
    height: auto;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

#home-footer {
    & span {
        width: 70px;
        height: 70px;
        padding: 2px;
        border: 1px solid #CCCCCC;
        border-radius: 100%;
        background-color: blue;

        cursor: default;
        user-select: none;
        pointer-events: none;

        font-size: 1.5rem;
        font-weight: 500;
        color: #E6E6E6;
        text-align: center;

        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
    }

    & span:nth-child(2) {
        width: auto;
        height: auto;
        border: none;
        border-radius: 0px;
        background-color: transparent;

        font-size: 1.3rem;
        font-weight: 400;
    }
}

/*
    APPLY GLOBAL STYLES ONLY TO SCREEN
*/
@media screen {
    #home-container {
        width: 50vw;
        height: 100vh;
        background-color: #202020;
        padding: 60px;

        display: flex;
        flex-flow: column;
        align-items: start;
        justify-content: space-between;
    }
}

/*
    APPLY GLOBAL STYLES ONLY TO PRINT VIEWS
*/
@media print {
    #home-container {
        width: 100%;
        height: 100%;

        background-color: blue;
        padding: 60px;

        display: flex;
        flex-flow: column;
        align-items: start;
        justify-content: space-between;
    }
}
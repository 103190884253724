/*
    APPLY GLOBAL STYLES TO ANY SCREEN
*/
#environments-container {
    height: 100vh;
    border-top: 1px solid #3E3E3E;
    background-color: #202020;
    padding: 0;

    position: relative;

    display: flex;
    flex-flow: column;
    align-items: start;
    justify-content: space-between;
    gap: 2rem;
}

#environments-header {
    width: 100%;
    height: 233px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    background-color: #1CB978;
    padding: 60px;

    display: flex;
    flex-flow: column;
    align-items: start;
    justify-content: start;
    gap: 1rem;

    & .general-text-bold,
    & .general-text-label {
        color: white;
    }

    & .general-text-bold {
        font-size: 2rem;
    }

    & .general-text-label {
        font-size: 1.1rem;
    }
}

#environments-content {
    width: calc(100% - 115px);
    min-height: 300px;
    max-height: none;
    height: auto;
    padding: 30px;

    margin: 0;
    position: absolute;
    top: 183px;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    flex-flow: column;
    align-items: start;
    justify-content: start;
    gap: 0.5rem;
}

.environments-li-item {
    width: 100%;
    min-height: 60px;
    height: auto;

    display: flex;
    flex-flow: row nowrap;
    align-items: start;
    justify-content: space-between;
    gap: 2rem;
}

.environments-li-header {
    width: auto;
    height: auto;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: start;
    gap: 1rem;
}

.environments-li-label {
    font-size: 1.1rem;
    color: white;
}

.environments-li-content {
    flex: 1;
    max-width: 60%;
    height: auto;
    border-radius: 20px;

    display: flex;
    flex-flow: row wrap;
    align-items: start;
    justify-content: end;
    gap: 1rem;
}

.environments-li-tag {
    min-width: 100px;
    padding: 0.75rem;
    border-radius: 20px;
    background-color: #366F56;
    cursor: default;

    font-size: 16px;
    color: white;
    text-align: center;
}

/*
    APPLY GLOBAL STYLES ONLY TO SCREEN
*/
@media screen {}

/*
    APPLY GLOBAL STYLES ONLY TO PRINT VIEWS
*/
@media print {
    .environments-li-item {
        &~& {
            margin-top: 1rem;
        }
    }
}
/*
    APPLY GLOBAL STYLES TO ANY SCREEN
*/

#summary-container {
    height: 100%;
    border-top: 1px solid #3E3E3E;
    background-color: transparent;
    background-color: #202020;

    position: relative;

    display: flex;
    flex-flow: column;
    align-items: start;
    justify-content: space-between;
    gap: 4rem;

    /* &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 50;
        background-image: url('../../../../../../assets/img/harvey-background-summary-translucide.png');
        opacity: 0.9;
    } */
}

#summary-header {
    width: 100%;
    height: auto;

    display: flex;
    flex-flow: column;
    align-items: start;
    justify-content: start;
    gap: 25px;
}

#summary-span-date,
#summary-span-text {
    width: 100%;
    height: auto;

    cursor: default;
    user-select: none;
    pointer-events: none;
}

#summary-span-date {
    font-size: 1rem;
    font-weight: 600;
    color: #ABABAB;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: end;
    gap: 10px;
}

#summary-span-text {
    font-size: 3rem;
    font-weight: 600;
    color: #37D895;
    line-height: 3.7rem;
}


#summay-real-estate-amount-image {
    width: auto;
    height: auto;

    position: absolute;
    left: -15px;

    & img {
        max-width: 54px;
        height: auto;
    }
}

#summary-content-box {
    width: 100%;
    height: auto;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: start;
    gap: 16px;

    & .general-box {
        height: 296px;

        flex-flow: column;
        align-items: start;
        justify-content: space-between;
        gap: 16px;
    }

    & .summary-text-group {
        width: 100%;
        height: auto;

        display: flex;
        flex-flow: column;
        gap: 10px;


    }
}

#summary-footer-box {
    width: 100%;
    height: 200px;
    background-color: #272727;
    border: 1px solid #3E3E3E;
    border-radius: 16px;
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 40px;

    display: flex;
    flex-flow: row nowrap;
    align-items: start;
    justify-content: space-between;
    gap: 2px;

    & .summary-box-real-estate-amounts {
        min-height: 0;
        height: 100%;
        border: 0;
        border-radius: 0;
        padding: 0;

        flex-flow: column;
        justify-content: space-between;
        gap: 60px;
    }

    & .line-separator {
        width: 2px;
        height: 40%;
        align-self: center;
    }

    & .general-text-label {
        width: 45%;
        text-align: center;
    }
}

#summary-box-real-estate-property {
    padding: 0;
    border: none;
    object-fit: contain;

    img {
        max-width: 100%;
        height: auto;
        margin-top: -25px;
    }
}


/*
APPLY GLOBAL STYLES ONLY TO SCREEN
*/
@media screen {
    #summary-span-date {
        font-size: 1rem;

    }

    #summary-span-text {
        font-size: 3rem;
    }
}


@media screen and (max-width: 800px) {
    #summary-span-text {
        font-size: 2rem;
        text-align: center;
    }

    #summary-box-real-estate-property {
        background-color: transparent;


        & img {
            margin-top: 50%;
        }
    }

    #summary-footer-box {
        flex-flow: row nowrap;
        overflow-x: auto;

        & .line-separator {
            width: 100%;
            height: 2px;
            display: none;
        }

        & .summary-box-real-estate-amounts {
            width: 100%;
            height: 100%;
        }
    }
}

/*
    APPLY GLOBAL STYLES ONLY TO PRINT VIEWS
*/
@media print {

    #summary-box-real-estate-amount,
    #summary-box-real-estate-result,
    #summary-box-real-estate-property {
        & .general-box {
            font-size: 17pt;
            padding-left: 20px;
            padding-right: 30px;
            height: 196px;
        }
    }

    .general-text-bold {
        font-size: 13pt;
    }

    .summary-text-group {
        font-size: 10pt;
    }

    #summary-box-real-estate-amount {
        .general-text-label {
            width: 90%;
        }
    }

    .summary-box-real-estate-amounts {
        & .general-text-label {
            width: 16%;
        }
    }

    #summary-container {
        gap: 10px;
    }

    #summary-span-text {
        width: 80%;
        font-size: 23pt;
        line-height: 40px;
    }

    #summary-content-box {

        & #summary-box-real-estate-result,
        & #summary-box-real-estate-property {
            justify-content: center;
            gap: 5px;
        }

        & .general-box {
            height: 196px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    #summary-box-real-estate-amount {

        height: 0px;
        padding-left: 60px;
        padding-right: 20px;

        & span:nth-child(2) {
            width: 70%;

            font-size: 12pt;
        }

        & span:nth-child(3) {
            flex: 1;
            font-size: 16pt;
            text-align: end;
        }
    }

    #summary-footer-box {
        & .general-text-label:first-child {
            width: 50%;
            text-align: center;
        }
    }
}
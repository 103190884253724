/*
    APPLY GLOBAL STYLES TO ANY SCREEN
*/
#personal-data-container {
    height: 100%;
    border-top: 1px solid #3E3E3E;
    background-color: #202020;

    position: relative;

    display: flex;
    flex-flow: column;
    align-items: start;
    justify-content: space-between;
    gap: 4rem;
}

#personal-data-header {
    width: 100%;
    height: auto;

    display: flex;
    flex-flow: column;
    align-items: start;
    gap: 1rem;
}

#personal-data-title {
    font-size: 2rem;
}

#personal-data-description {
    font-size: 1.25rem;
    font-weight: 600;
}

.personal-data-box {
    max-height: none;
    height: auto;

    flex-flow: column;
    align-items: start;
    justify-content: start;
    gap: 2rem;
}

.personal-data-box-content {
    width: 100%;
    height: auto;

    display: flex;
    flex-flow: column;
    align-items: start;
    justify-content: start;
    gap: 1rem;
}

.personal-data-box-group-text {
    width: auto;
    height: auto;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 2rem;
}

.personal-data-box-row {
    width: auto;
    height: auto;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.7rem;
}

.personal-data-box-header {
    width: 100%;
    height: auto;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: start;
    gap: 1rem;

    & .general-text-bold {
        font-size: 1.25rem;
        font-weight: 600;
    }

    & img {
        width: 30px;
        height: auto;
        margin-top: 5px;
    }
}

#personal-wrapper-image {
    flex: 1;
    height: auto;

    position: relative;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 0;
}

.personal-image {
    width: 50px;
    height: 50px;
    background-color: #b6b6b6;
    border-radius: 100%;
    border: 1px solid #CCCCCC;

    cursor: default;
    pointer-events: none;
    user-select: none;

    font-weight: 500;
    color: #E6E6E6;

    overflow: hidden;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    &:nth-child(1) {
        position: absolute;
        top: 0;
        right: 40px;
    }

    &:nth-child(2) {
        background-color: blue;
    }

    object-fit: fill;

    & img {
        max-width: 100%;
        height: 100%;
    }
}

/*
    APPLY GLOBAL STYLES ONLY TO SCREEN
*/
@media screen {}

/*
    APPLY GLOBAL STYLES ONLY TO PRINT VIEWS
*/
@media print {

    #personal-data-container {
        gap: 2rem;
    }

    .personal-data-box {
        padding-top: 25px;
        padding-bottom: 25px;
        gap: 2.4rem;
    }
}
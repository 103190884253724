.option-item-container:hover[data-byuser=true] {
    & .delete-option {
        left: 10.5px;
        opacity: 1;
    }
}

.delete-option-item {
    margin: 0 auto;
    z-index: 0;
    opacity: 0;
}
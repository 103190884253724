.overlay-motion {
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.content-motion {
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.on-mount-motion {
    animation: onMountOptionContent 3s ease-in-out infinite;
}

.transitions-motion {
    transition: ease-in-out 250ms;
}

.modal-overlay {
    background-color: #f5f5f590;
    backdrop-filter: blur(15px);
}

@keyframes overlayShow {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes contentShow {
    from {
        opacity: 0;
        transform: translate(-50%, -48%) scale(0.96);
    }

    to {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1)
    }
}

@keyframes onMountOptionContent {
    0% {
        transform: scale(0.7);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.7);
    }
}